import { Component, For, Show, createResource } from 'solid-js'
import { useNavigate } from '@solidjs/router'
import Button from '~/components/button/button'
import SeriesRow from '~/components/series-row/series-row'
import styles from '~/components/series/series.module.scss'
import stylesDash from '~/components/dashboard/dashboard.module.scss'
import { getSeriesList } from '~/services/database/series'
import { translations } from '~/translations'
import DocumentEmptyIcon from '~/assets/icons/document-empty.svg'
import EmptyState from '~/components/empty-state/empty-state'

const Series: Component = () => {
  let seriesListRef!: HTMLDivElement
  const [series, { refetch }] = createResource(getSeriesList)
  const navigate = useNavigate()
  const onCreateSeries = () => {
    navigate('/series/create')
  }

  const seriesExist = () => {
    const seriesCount = series()?.length ?? 0
    return !series.loading && seriesCount > 0
  }

  const updateMaxColWidth = (newWidth: number) => {
    const currentWidth = parseInt(getComputedStyle(seriesListRef).getPropertyValue('--table-clients'))
    if(newWidth > currentWidth){
      seriesListRef.style.setProperty('--table-clients', `${newWidth}px`)
    }
  }

  return (
    <div class={stylesDash.dashboard}>
      <aside class={stylesDash.aside}>
        <Button onClick={onCreateSeries}>
          {translations().series.create}
        </Button>
      </aside>
      <div class={styles.seriesList} ref={seriesListRef}>
        <img src='/assets/images/illustration-people1.png' class={styles.illustration} />
        <div class={styles.title}>
          {translations().series.title}
        </div>
        <div class={styles.tableHead}>
          <div class={styles.column}>
            {translations().series.client}
          </div>
          <div class={styles.column}>
            {translations().series.series}
          </div>
          <div class={styles.column}>
            {translations().series.chapters}
          </div>
          <div class={styles.column}>
            {translations().series.lastUpdate}
          </div>
        </div>
        <div class={styles.list}>
          <Show
            when={seriesExist()}
            fallback={(
              <EmptyState
                icon={DocumentEmptyIcon}
                label={translations().series.emptyState.title}
                description={translations().series.emptyState.description}
              />
            )}
          >
            <For each={series()}>
              {series => (
                <SeriesRow
                  row={series}
                  onMutate={refetch}
                  onReady={updateMaxColWidth}
                />
              )}
            </For>
          </Show>
        </div>
      </div>
    </div>
  )
}

export default Series