import Series from '~/components/series/series'
import Header from '~/components/header/header'

export default function ChapterList() {
  return (
    <>
      <Header />
      <Series />
    </>
  )
}
