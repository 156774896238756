import { A } from '@solidjs/router'
import { Component, createResource, createSignal, onMount } from 'solid-js'
import ConfirmationModal from '~/components/confirmation-modal/confirmation-modal'
import RowButton from '~/components/row-button/row-button'
import { SeriesRowProps } from '~/components/series-row/series-row.interfaces'
import styles from '~/components/series-row/series-row.module.scss'
import { deleteSeries } from '~/services/database/series'
import ClockIcon from '~/assets/icons/clock.svg'
import DeleteIcon from '~/assets/icons/delete.svg'
import { reportError } from '~/services/errors'
import { revokeSeriesPermission } from '~/services/database/permissions'
import { PostgrestError } from '@supabase/supabase-js'
import { getFullDate } from '~/helpers/dates'
import { getCollaborators } from '~/services/database/collaborators'
import { user } from '~/services/database/users'
import { translations } from '~/translations'

const SeriesRow: Component<SeriesRowProps> = ({ row, onMutate, onReady }) => {
  let clientColRef!: HTMLDivElement
  const [openConfirmationModal, setConfirmationModal] = createSignal(false)
  const lastUpdate = getFullDate(new Date(row.created_at))
  const link = `/series/${row.id}`
  const chapterCount = row.chapters[0].count

  const [collaborators] = createResource(getCollaborators(row.id))
  const userEntry = () => collaborators.latest?.find(entry => entry.userId === user()?.id)
  const canEditSeries = () => userEntry()?.permission === 'admin'

  const confirmAction = async (event: MouseEvent) => {
    event.preventDefault()
    setConfirmationModal(true)
  }

  const onDelete = async () => {
    try{
      await revokeSeriesPermission({ seriesId: row.id })
      await deleteSeries({ id: row.id })
      onMutate()
    }catch(error){
      reportError(error as PostgrestError)
    }
  }

  onMount(() => {
    onReady(clientColRef.offsetWidth)
  })

  return (
    <A class={styles.seriesRow} href={link}>
      <div class={styles.client} ref={clientColRef}>
        {row.client}
      </div>
      <div class={styles.name}>
        <span>{row.title}</span>
      </div>
      <div class={styles.chapterCount}>{translations().series.chapterCount(chapterCount)}</div>
      <div class={styles.lastUpdate} title={translations().series.lastUpdate}>
        <ClockIcon class={styles.icon} /> {lastUpdate}
      </div>
      {canEditSeries() && (
        <RowButton
          className={styles.actionIcon}
          icon={DeleteIcon}
          label={translations().general.actions.delete}
          onClick={confirmAction}
        />
      )}
      <ConfirmationModal
        type='danger'
        onConfirm={onDelete}
        title={translations().series.deleteConfirmation.title}
        description={translations().series.deleteConfirmation.description}
        open={openConfirmationModal()}
        setOpen={setConfirmationModal}
      />
    </A>
  )
}

export default SeriesRow
